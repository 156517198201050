import {
  AbsintheSocket,
  send,
  toObservable,
  unobserveOrCancel,
} from '@absinthe/socket'
import { ApolloLink } from '@apollo/client'
import { print } from 'graphql'
import type { DocumentNode } from 'graphql/language/ast'

type ApolloOperation = {
  query: DocumentNode
  variables: Record<string, any>
}

let absintheSocket: AbsintheSocket | null = null

export function setAbsintheSocket(newAbsintheSocket: AbsintheSocket) {
  const oldPhoenixSocket = absintheSocket?.phoenixSocket
  if (oldPhoenixSocket) {
    oldPhoenixSocket.disconnect()
  }
  absintheSocket = newAbsintheSocket
}

const unobserveOrCancelIfNeeded = (absintheSocket, notifier, observer) => {
  if (notifier && observer) {
    unobserveOrCancel(absintheSocket, notifier, observer)
  }
}

const notifierToObservable = (absintheSocket, onError, onStart) => notifier =>
  toObservable(absintheSocket, notifier, {
    onError,
    onStart,
    unsubscribe: unobserveOrCancelIfNeeded,
  })

const getRequest = ({ query, variables }: ApolloOperation) => ({
  operation: print(query),
  variables,
})

function compose(...fns) {
  // eslint-disable-line no-redeclare
  const len = fns.length - 1
  return x => {
    let y = x
    for (let i = len; i > -1; i--) {
      y = fns[i].call(this, y)
    }
    return y
  }
}

/**
 * Creates a terminating ApolloLink to request operations using given
 * AbsintheSocket instance
 */
const createAbsintheSocketLink = (onError?, onStart?) => {
  return new ApolloLink((operation, _forward) => {
    return compose(
      notifierToObservable(absintheSocket, onError, onStart),
      request => (absintheSocket ? send(absintheSocket, request) : null),
      getRequest
    )(operation)
  })
}

export default createAbsintheSocketLink
