import * as AbsintheSocket from '@absinthe/socket'
import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
  InMemoryCacheConfig,
  NormalizedCacheObject,
  split,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { getMainDefinition } from '@apollo/client/utilities'
import merge from 'deepmerge'
import isEqual from 'lodash/isEqual'
import { getCsrfToken } from 'lol-util'
import { Socket as PhoenixSocket } from 'phoenix'
import { useMemo } from 'react'

import createAbsintheSocketLink, {
  setAbsintheSocket,
} from './create_absinthe_socket_link'
import { registerAbsintheSocket } from './helpers'

type CreateApolloClientOpts = {
  cookie?: string
  uri: string
  onGraphQLErrors?: any
  cacheConfig?: InMemoryCacheConfig
}

type SocketConfig = {
  url: string
  token: string
  user_id: string
}

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null
let _stringifiedSocketConfig: string

export async function resetStore() {
  apolloClient && (await apolloClient.resetStore())
}

export async function clearStore() {
  apolloClient && (await apolloClient.clearStore())
}

const isServer = () => typeof window === 'undefined'

const registrationLink = setContext((_, { headers, clientRegistrationId }) => {
  return {
    headers: {
      ...headers,
      ...(clientRegistrationId
        ? { 'x-registration-id': clientRegistrationId }
        : {}),
    },
  }
})

const csrfLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'x-csrf-token': getCsrfToken(),
    },
  }
})

const errorLink = onGraphQLErrors =>
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.error(
          `[GraphQL error]: Message: ${message}`,
          `Path: ${path}`,
          'Location:',
          locations
        )
      )
      onGraphQLErrors && onGraphQLErrors(graphQLErrors)
    }

    if (networkError) console.error(`[Network error]: ${networkError}`)
  })

const createAuthLink = cookie =>
  setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Cookie: cookie,
      },
    }
  })

const updateAbsintheSocket = (config: SocketConfig | null) => {
  const { url, token, user_id: userId } = config || {}

  if (url) {
    const socket = new PhoenixSocket(url, {
      transport: WebSocket,
      params: {
        token,
      },
    })
    socket.connect()

    registerAbsintheSocket(socket, userId)

    setAbsintheSocket(AbsintheSocket.create(socket))
  }
}

const clientLink = (uri, onGraphQLErrors) =>
  split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    createAbsintheSocketLink(
      error => console.error(error),
      () => {
        return
      }
    ),
    from([
      errorLink(onGraphQLErrors),
      csrfLink,
      registrationLink,
      createHttpLink({
        uri,
        credentials: 'include',
      }),
    ])
  )

const serverLink = (uri, cookie, onGraphQLErrors) =>
  from([
    errorLink(onGraphQLErrors),
    createAuthLink(cookie),
    createHttpLink({
      uri,
    }),
  ])

const createApolloClient = (opts: CreateApolloClientOpts) => {
  const cookie = opts.cookie || null
  const { uri, onGraphQLErrors, cacheConfig } = opts
  const client = new ApolloClient({
    ssrMode: isServer(),
    link: isServer()
      ? serverLink(uri, cookie, onGraphQLErrors)
      : clientLink(uri, onGraphQLErrors),
    cache: new InMemoryCache(cacheConfig),
  })

  return client
}

export function initializeApollo(initialState, opts: CreateApolloClientOpts) {
  const _apolloClient = apolloClient ?? createApolloClient(opts)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s))),
      ],
    })

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}

export function useApollo(
  uri,
  pageProps,
  onGraphQLErrors,
  cacheConfig?: InMemoryCacheConfig
) {
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  const socketConfig = pageProps.socketConfig

  if (!isServer()) {
    // initialize the apolloSocket before the app calls any hooks
    const stringifiedSocketConfig = JSON.stringify(socketConfig)
    if (stringifiedSocketConfig !== _stringifiedSocketConfig) {
      _stringifiedSocketConfig = stringifiedSocketConfig
      updateAbsintheSocket(socketConfig)
    }
  }

  const store = useMemo(
    () => initializeApollo(state, { uri, onGraphQLErrors, cacheConfig }),
    [state]
  )
  return store
}
