import '../styles/globals.scss'

import { ApolloProvider } from '@apollo/client'
import { awakeningEmbed } from '@legends/lol-gameframe'
import { useApollo } from 'lol-apollo'
import { gtmVirtualPageView } from 'lol-util'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import Script from 'next/script'
import NextAdapter from 'next-query-params'
import { useEffect } from 'react'
import { Toaster } from 'react-hot-toast'
import { routes } from 'src/helpers/routes'
import { QueryParamProvider } from 'use-query-params'

import { API_URL, GRAPHQL_URL, GTM_ID } from '../config'
import { currentPath, studentSignInUrl } from '../helpers/urls'

function isServer() {
  return typeof window === 'undefined'
}

// Listen for messages from this window's child and forward them to this
// window's parent (i.e. the innermost window's grandparent)
if (!isServer()) {
  window?.addEventListener('message', function (event) {
    if (window.parent != window) window.parent.postMessage(event.data, '*')
  })
}

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()

  function handleNotAuthorized(graphQLErrors) {
    const invalidRegistration =
      graphQLErrors.filter(error => error.message === 'Invalid Registration')
        .length > 0

    if (invalidRegistration) {
      console.error('HANDLE_NOT_AUTHORIZED Redirect.')

      router.push(routes.multisessionError)
    }
  }

  function handleInvalidRegistration(graphQLErrors) {
    const notAuthenticated =
      graphQLErrors.filter(error => error.message === 'Not authenticated')
        .length > 0

    if (notAuthenticated) {
      console.error('HANDLE_INVALID_REGISTRATION GraphQL RELOAD.')

      const redirectPath = studentSignInUrl(currentPath())
      // const redirectPath =
      //   isSchoolTime() && ['/join', '/join/teachers'].includes(router.asPath)
      //     ? studentSignInUrl(currentPath())
      //     : '/awakening'

      router.push(redirectPath)
    }
  }

  const apolloClient = useApollo(
    GRAPHQL_URL,
    pageProps,
    graphQLErrors => {
      if (awakeningEmbed) {
        window.postMessage(
          { messageName: 'graphQLErrors', payload: graphQLErrors },
          '*'
        )
      } else {
        handleNotAuthorized(graphQLErrors)
        handleInvalidRegistration(graphQLErrors)
      }
    },
    {
      typePolicies: {
        AssignmentPlayStep: {
          fields: {
            remainingQuestions: {
              merge(_existing, incoming) {
                return incoming
              },
            },
          },
        },
      },
    }
  )

  useEffect(() => {
    const handleRouteChange = url => {
      gtmVirtualPageView(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <>
      <Script
        id="google-tag-manager"
        strategy="afterInteractive"
        defer
        type="module"
      >
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GTM_ID}');
      `}
      </Script>
      <Script id="tz-manager">
        {`
          var tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
          var xhr = new XMLHttpRequest();
          xhr.onreadystatechange = function () {
            if (this.readyState != 4) return;
            if (this.status == 200) {}
          };
          xhr.open('GET', '${API_URL}/tz?iana=' + encodeURIComponent(tz), true);
          xhr.send();
        `}
      </Script>
      <ApolloProvider client={apolloClient}>
        {isServer() ? (
          <>
            <Toaster
              position="top-right"
              toastOptions={{
                duration: 5000,
                success: { className: 'border-l-8 border-[#61d345]' },
                error: { className: 'border-l-8 border-[#ff4b4b]' },
              }}
            />
            <Component {...pageProps} />
          </>
        ) : (
          <QueryParamProvider adapter={NextAdapter}>
            <Toaster
              position="top-right"
              toastOptions={{
                duration: 5000,
                success: { className: 'border-l-8 border-[#61d345]' },
                error: { className: 'border-l-8 border-[#ff4b4b]' },
              }}
            />
            <Component {...pageProps} />
          </QueryParamProvider>
        )}
      </ApolloProvider>
    </>
  )
}

export default MyApp
