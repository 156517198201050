import { API_URL } from '../config'

export const routes = {
  joinIndex: '/join',
  confirmJoinAwakening: '/join/confirm-awakening',
  joinTeachers: '/join/teachers',
  joinTeacherById: ({ id }: { id: string }) => `/join/teachers/${id}`,
  selectTeacher: '/join/select',
  noAssignments: '/join/no-assignments',
  joinAfterSchool: '/join/after-school',
  modeSelect: '/join/mode/select',
  logout: `${API_URL}/logout`,
  privacyPolicy:
    'https://www.legendsoflearning.com/terms-and-conditions/privacy-policy/',
  multisessionError: '/assignments/error/multisession',
  appDownload: '/join/app-download',
}
