export const bindNoun = (
  amount: number,
  baseText: string,
  pluralVariation: string,
  singularVariation = ''
) => `${baseText}${amount === 1 ? singularVariation : pluralVariation}`

export const upperCaseFirstChar = (text = '') =>
  text.replace(
    /^(.)/g,
    (match, firstChar) => `${firstChar?.toUpperCase()}${match.slice(1)}`
  )

const DEFAULT_TTL_SECONDS = 30
export const urlWithTtl = (url?: string | null, ttl?: number) =>
  url
    ? `${url}?v=${Math.floor(Date.now() / ((ttl || DEFAULT_TTL_SECONDS) * 1000))}`
    : undefined
