import Cookies from 'js-cookie'

const CSRF_COOKIE_NAME = '_lol_csrf_token'

export function getCsrfToken(): string {
  return Cookies.get(CSRF_COOKIE_NAME) || ''
}

export function setCsrfToken(value: string, cookieDomain: string) {
  Cookies.set(CSRF_COOKIE_NAME, value, {
    secure: true,
    sameSite: 'None',
    domain: cookieDomain,
  })
}
