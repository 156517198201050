/**
 * Register the current user socket to allow rebalancing later, currently absinthe doens't have a hook for this.
 * @param socket phoenix socket used to connect absinthe subscriptions
 */
export function registerAbsintheSocket(socket, userId) {
  socket
    .channel(`__lol_socket_register__:${userId}`, {})
    .join()
    .receive('ok', ({ messages }) =>
      console.debug('ABSINTHE REGISTERED', messages)
    )
    .receive('error', ({ reason }) =>
      console.debug('FAILED TO REGISTER ABSINTHE SOCKET', reason)
    )
    .receive('timeout', () =>
      console.debug('FAILED TO REGISTER ABSINTHE SOCKET. WAITING...')
    )
}
