import qs from 'query-string'

import { API_URL } from '../config'

export const studentSignInUrl = redirectPath => {
  const redirectUrl = redirectorUrl(redirectPath)

  return `${API_URL}/login/students/sign_in?${qs.stringify({
    user_return_to: redirectUrl,
  })}`
}

export const currentPath = () =>
  `${window.location.pathname}${window.location.search}`

export const redirectorUrl = path => `/play/v8/redirector?path=${path}`
